











import { Component, Ref, Vue } from 'vue-property-decorator';

import ChangePassword from './components/ChangePassword.vue';
import Basic from './components/Basic.vue';

@Component({
    name: 'UserIndex',
    components: {
        ChangePassword,
        Basic
    }
})
export default class UserIndex extends Vue {
    @Ref('ChangePassword')
    private readonly ChangePasswordRef!:any;

    public loading: boolean = false;

    public async submit() {
        try {
            this.loading = true;
            await this.ChangePasswordRef.submit();
            this.$emit('submit');
        } catch (err) {
            console.warn(err);
        } finally {
            this.loading = false;
        }
    }
}
