import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';
Vue.use(VueRouter);

/*
    没有权限要求的基页
    所有角色都可以访问
*/

export const constantRoutes: RouteConfig[] = [
    {
        path: '/redirect',
        component: Layout,
        meta: { hidden: true },
        children: [
          {
            path: '/redirect/:path(.*)',
            component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index.vue')
          }
        ]
    },
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
        meta: { hidden: true }
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue'),
        meta: { hidden: true }
    },
    {
        path: '/401',
        component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401.vue'),
        meta: { hidden: true }
    },
    {
        path: '/',
        component: Layout,
        meta: {
            title: '自提點管理',
            icon: 'el-icon-pie-chart',
            noCache: true,
            breadcrumb: false
        },
        redirect: '/carry-point',
        children: [
            {
                component: () => import(/* webpackChunkName: "CarryManagement" */ '@/views/carry-management/index.vue'),
                path: 'carry-point',
                name: 'CarryPoint',
                meta: {
                    title: '訂單列表',
                    icon: 'el-icon-tickets',
                    noCache: true
                }
            }
        ]
    },
    { // 注意这里必须放在最后，不然会有异步问题
        path: '*',
        redirect: '/404',
        meta: { hidden: true, title: '无页面', space: 'Undefined' }
    }
];

/*
    需要根据用户角色动态加载的路由
    // 注：这里的权限已重新修改过。文档中的权限已无效
    这里 拿  space 作为标识
*/

export const asyncRoutes: RouteConfig[] = [
];

const createRouter = () => new VueRouter({
    // mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    // base: process.env.BASE_URL,
    routes: constantRoutes
});

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
