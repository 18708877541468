
























import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import SidebarItem from './SidebarItem.vue';
import variables from '@/styles/_variables.scss';
import { PermissionModule } from '@/store/modules/permission';

@Component({
    name: 'SideBar',
    components: {
        SidebarItem
    }
})
export default class extends Vue {
    get sidebar() {
        return AppModule.sidebar;
    }

    get routes() {
        return PermissionModule.routes;
    }

    get variables() {
        return variables;
    }

    get activeMenu() {
        const route = this.$route;
        const { meta, path } = route;
        if (meta.activeMenu) {
            return meta.activeMenu;
        }
        return path;
    }

    get isCollapse() {
        return !this.sidebar.opened;
    }
}
