
// 按照后端格式前端生成json去查询
// 后端 枚举文档： https://www.donet5.com/home/doc?masterId=1&typeId=2314
/*
    生成格式参数解释:
    {"FieldName":"ShopName","ConditionalType":"1","FieldValue":"速风"}
    FieldName = key
    FieldValue = 查询得值
    ConditionalType = 参照以下枚举
*/
export enum ConditionalTypeEnum {
    '等于' = 0,
    '模糊查询' = 1,
    '大于' = 2,
    '大于等于' = 3,
    '小于' = 4,
    '小于等于' = 5
}

export const Expressionable = (paramsArr: Array<object>) => {
    const queryParmas: Array<object> = [];
    for (let index = 0; index < paramsArr.length; index++) {
        const row: any = paramsArr[index];
        queryParmas.push({
            FieldName: row.lable,
            FieldValue: row.value,
            ConditionalType: row.query
        });
    }
    return queryParmas.length ? JSON.stringify(queryParmas) : [];
};
