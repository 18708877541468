




































import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import Breadcrumb from '@/components/Breadcrumb/index.vue';
import Hamburger from '@/components/Hamburger/index.vue';
import YwhzUser from '@/views/user/index.vue';

import { LoadingClass } from '@/utils/tool';
@Component({
    name: 'Navbar',
    components: {
        Breadcrumb,
        Hamburger,
        YwhzUser
    }
})
export default class extends Vue {
    public dialogVisible: boolean = false;

    private LoadingPro: any = new LoadingClass();

    get sidebar() {
        return AppModule.sidebar;
    }

    get device() {
        return AppModule.device.toString();
    }

    get avatar() {
        return UserModule.avatar;
    }

    public toggleSideBar() {
        AppModule.ToggleSideBar(false);
    }

    public async logout() {
        await UserModule.LogOut();
        this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    }
}
