













import { compile } from 'path-to-regexp';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RouteRecord, Route } from 'vue-router';

@Component({
    name: 'Breadcrumb'
})
export default class extends Vue {
    public breadcrumbs: RouteRecord[] = [];

    @Watch('$route')
    public onRouteChange(route: Route) {
        // if you go to the redirect page, do not update the breadcrumbs
        if (route.path.startsWith('/redirect/')) {
            return;
        }
        this.getBreadcrumb();
    }

    created() {
        this.getBreadcrumb();
    }

    public getBreadcrumb() {
        const matched = this.$route.matched.filter((item) => item.meta && item.meta.title);
        const first = matched[0];
        if (!this.isDashboard(first)) {
            // matched = [{ path: '/home', meta: { title: '首页' } } as RouteRecord].concat(matched);
        }
        this.breadcrumbs = matched.filter((item) => {
            return item.meta && item.meta.title && item.meta.breadcrumb !== false;
        });
    }

    public isDashboard(route: RouteRecord) {
        const name = route && route.name;
        if (!name) {
            return false;
        }
        return name.trim().toLocaleLowerCase() === 'Home'.toLocaleLowerCase();
    }

    public pathCompile(path: string) {
        // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
        const { params } = this.$route;
        const toPath = compile(path);
        return toPath(params);
    }

    public handleLink(item: any) {
        const { redirect, path } = item;
        if (redirect) {
            this.$router.push(redirect);
            return;
        }
        this.$router.push(this.pathCompile(path));
    }
}
