


























import path from 'path';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { PermissionModule } from '@/store/modules/permission';
import { TagsViewModule, ITagView } from '@/store/modules/tags-view';
import ScrollPane from './ScrollPane.vue';

@Component({
    name: 'TagsView',
    components: {
        ScrollPane
    }
})
export default class extends Vue {
    public visible = false;
    public top = 0;
    public left = 0;
    public selectedTag: ITagView = {};
    public affixTags: ITagView[] = [];

    get visitedViews() {
        return TagsViewModule.visitedViews;
    }

    get routes() {
        return PermissionModule.routes;
    }

    @Watch('$route')
    public onRouteChange() {
        this.addTags();
        this.moveToCurrentTag();
    }

    @Watch('visible')
    public onVisibleChange(value: boolean) {
        if (value) {
            document.body.addEventListener('click', this.closeMenu);
        } else {
            document.body.removeEventListener('click', this.closeMenu);
        }
    }

    mounted() {
        this.initTags();
        this.addTags();
    }

    public isActive(route: ITagView) {
        return route.path === this.$route.path;
    }

    public isAffix(tag: ITagView) {
        return tag.meta && tag.meta.affix;
    }

    public filterAffixTags(routes: RouteConfig[], basePath = '/') {
        let tags: ITagView[] = [];
        routes.forEach(route => {
            if (route.meta && route.meta.affix) {
                const tagPath = path.resolve(basePath, route.path);
                tags.push({
                    fullPath: tagPath,
                    path: tagPath,
                    name: route.name,
                    meta: { ...route.meta }
                });
            }
            if (route.children) {
                const childTags = this.filterAffixTags(route.children, route.path);
                if (childTags.length >= 1) {
                    tags = [...tags, ...childTags];
                }
            }
        });
        return tags;
    }

    public initTags() {
        this.affixTags = this.filterAffixTags(this.routes);
        for (const tag of this.affixTags) {
            // Must have tag name
            if (tag.name) {
                TagsViewModule.addVisitedView(tag);
            }
        }
    }

    public addTags() {
        const { name } = this.$route;
        if (name) {
            TagsViewModule.addView(this.$route);
        }
        return false;
    }

    public moveToCurrentTag() {
        const tags = this.$refs.tag as any[]; // 更好地支持路由器链接的类型脚本
        this.$nextTick(() => {
            for (const tag of tags) {
                if ((tag.to as ITagView).path === this.$route.path) {
                    (this.$refs.scrollPane as ScrollPane).moveToTarget(tag as any);
                    // When query is different then update
                    if ((tag.to as ITagView).fullPath !== this.$route.fullPath) {
                        TagsViewModule.updateVisitedView(this.$route);
                    }
                    break;
                }
            }
        });
    }

    public refreshSelectedTag(view: ITagView) {
        TagsViewModule.delCachedView(view);
        const { fullPath } = view;
        this.$nextTick(() => {
            this.$router.replace({
                path: '/redirect' + fullPath
            }).catch(err => {
                console.warn(err);
            });
        });
    }

    public closeSelectedTag(view: ITagView) {
        TagsViewModule.delView(view);
        if (this.isActive(view)) {
            this.toLastView(TagsViewModule.visitedViews, view);
        }
    }

    public closeOthersTags() {
        if (this.selectedTag.fullPath !== this.$route.path && this.selectedTag.fullPath !== undefined) {
            this.$router.push(this.selectedTag.fullPath).catch(err => {
                console.warn(err);
            });
        }
        TagsViewModule.delOthersViews(this.selectedTag);
        this.moveToCurrentTag();
    }

    public closeAllTags(view: ITagView) {
        TagsViewModule.delAllViews();
        if (this.affixTags.some(tag => tag.path === this.$route.path)) {
            return;
        }
        this.toLastView(TagsViewModule.visitedViews, view);
    }

    public toLastView(visitedViews: ITagView[], view: ITagView) {
        const latestView = visitedViews.slice(-1)[0];
        if (latestView !== undefined && latestView.fullPath !== undefined) {
            this.$router.push(latestView.fullPath).catch(err => {
                console.warn(err);
            });
        } else {
            // Default redirect to the home page if there is no tags-view, adjust it if you want
            if (view.name === 'Dashboard') {
                // to reload home page
                this.$router.replace({ path: '/redirect' + view.fullPath }).catch(err => {
                    console.warn(err);
                });
            } else {
                this.$router.push('/').catch(err => {
                    console.warn(err);
                });
            }
        }
    }

    public openMenu(tag: ITagView, e: MouseEvent) {
        const menuMinWidth = 105;
        const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
        const offsetWidth = (this.$el as HTMLElement).offsetWidth; // container width
        const maxLeft = offsetWidth - menuMinWidth; // left boundary
        const left = e.clientX - offsetLeft + 15; // 15: margin right
        if (left > maxLeft) {
            this.left = maxLeft;
        } else {
            this.left = left;
        }
        this.top = e.clientY;
        this.visible = true;
        this.selectedTag = tag;
    }

    public closeMenu() {
        this.visible = false;
    }

    public handleScroll() {
        this.closeMenu();
    }
}
