import TableCard from '@/components/YwhzTableCard/index.vue';
import SearchCard from '@/components/YwhzSearchCard/index.vue';

const ssdComponents = {
    install: (Vue: any, option: any) => {
        const name: string = option.componentPrefix;
        Vue.component(`${name}TableCard`, TableCard);
        Vue.component(`${name}SearchCard`, SearchCard);
    }
};
export default ssdComponents;
