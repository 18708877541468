



































import { LoadingClass, getUuiD } from '@/utils/tool';
import ywhzPaginationCard from '@/components/YwhzPaginationCard/index.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'YwhzTableCard',
    components: {
        ywhzPaginationCard
    }
})
export default class YwhzTableCard extends Vue {
    // eslint-disable-next-line no-undef
    [x: string]: any;

    @Prop({
        type: Array,
        default: () => []
    })
    public columns: any;

    @Prop({
        type: Object,
        default: {}
    })
    private listConfig: any;

    // 组件数据
    public tableData = [];

    // 页码
    public pagination: any = {
        page: 1,
        size: 30,
        total: 0
    };

    // loading 类名
    public className: string = 'loding' + getUuiD();

    // 数据合并处理返回函数
    private formatter(data: any) {
        return data;
    }

    // 初始化
    private async init(params?: any) {
        const Loading = new LoadingClass();
        Loading.lodingService({
            target: '.' + this.className
        });
        try {
            let { DataList, PageIndex, PageSize, Total } = await this.listConfig.constructor(params);
            this.pagination.page = PageIndex || 0;
            this.pagination.size = PageSize || 0;
            if (typeof this.listConfig.formatter === 'function') {
                DataList = this.formatter(this.listConfig.formatter(DataList));
            }
            this.tableData = DataList;
            this.pagination.total = Total || 0;
        } catch (err) {
            console.warn(err);
        } finally {
            Loading.lodingClose();
        }
    }

    public handleCurrentChange(page: string) {
        this.pagination.page = page;
        this.$emit('change');
    }

    public handleSizeChange(pageSize: string) {
        this.pagination.size = pageSize;
        this.$emit('change');
    }

    // 特殊列得 内部方法 根据自定义禁用判断是否可以勾选
    public selectable(row) {
        return row.customDisabled;
    }

}
