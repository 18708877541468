import router from '@/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Message } from 'element-ui';

import { Route } from 'vue-router';
import { UserModule } from '@/store/modules/user';
import { PermissionModule } from '@/store/modules/permission';

NProgress.configure({ showSpinner: false });

const whiteList = ['/login'];

router.beforeEach(async(to: Route, _: Route, next: any) => {
    // 启动进度条
    NProgress.start();
    // 确定用户是否已登录
    if (UserModule.token) {
        if (to.path.indexOf('login') !== -1) {
            // 如果已登录，请重定向到主页
            next({ path: '/' });
            NProgress.done();
        } else {
            if (UserModule.roles.length === 0) {
                try {
                    // 后端业务逻辑，未分配权限
                    // 获取路由
                    await UserModule.roleRouter();
                    // // 获取可访问得路由表json
                    PermissionModule.GenerateRoutes(['admin']);
                    // // 动态添加可访问的路由
                    PermissionModule.dynamicRoutes.forEach(route => {
                        router.addRoute(route);
                    });
                    // 设置replace:true，这样导航就不会留下历史记录
                    next({ ...to, replace: true });
                } catch (err) {
                    // 删除令牌并重定向到登录页面
                    UserModule.ResetToken();
                    next(`/login?redirect=${to.path}`);
                    NProgress.done();
                    Message({
                        type: 'error',
                        message: err
                    });
                }
            } else {
                next();
            }
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免费登录白名单中，直接进入
            next();
        } else {
            // 其他没有访问权限的页面被重定向到登录页面
            next(`/login?redirect=${to.path}`);
            NProgress.done();
        }
    }
});

router.afterEach((to: Route) => {
    // 结束进度条
    NProgress.done();
    // 设置页面标题
    if (to.meta.title) {
        document.title = to.meta.title + '-' + '網站管理後台';
    }
});
