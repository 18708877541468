import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { RouteConfig } from 'vue-router';
import { asyncRoutes, constantRoutes } from '@/router';
import store from '@/store';
// 判断是否有相应权限 这里根据 后端返回得json来做对比
const hasPermission = (roles: any, route: RouteConfig) => {
    return roles.some((itemRoles: any) => {
        let is = false;
        if (itemRoles.space === route.meta.space) {
            is = true;
        } else if (itemRoles.children) {
            is = hasPermission(itemRoles.children, route);
        }
        return is;
    });
};
// 查找相应权限
export const filterAsyncRoutes = (routes: RouteConfig[], roles: any) => {
    const res: RouteConfig[] = [];
    routes.forEach((itemRoute: any) => {
        const r2 = { ...itemRoute };
        if (hasPermission(roles, r2)) {
            if (r2.children) {
                r2.children = filterAsyncRoutes(r2.children, roles);
            }
            res.push(r2);
        }
    });
    return res;
};

// 添加权限到路由中
export const addRouterRoles = (allRouter: any, jurisdictions: any) => {
    allRouter.forEach((itemRouter: any) => {
        const isArr: any = AddPermission(itemRouter, jurisdictions);
        if (isArr.is) {
            itemRouter.roles = isArr.operation;
        }
        if (itemRouter.children) {
            addRouterRoles(itemRouter.children, jurisdictions);
        }
    });
    return allRouter;
};

// allRouter 自己的路由表
// jurisdictions后端返回的
export const AddPermission = (allRouter: any, jurisdictions: any) => {
    let res = {};
    jurisdictions.forEach((itemRouter: any) => {
        if (allRouter.meta.space === itemRouter.space) {
            if (itemRouter.operation !== null) {
                allRouter.meta.roles = itemRouter.operation;
                res = allRouter;
            }
        } else if (itemRouter.children) {
            const isArr: any = AddPermission(allRouter, itemRouter.children);
            if (isArr.id) {
                res = isArr;
            }
        }
    });
    return res;
};

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule {
    public routes: RouteConfig[] = [];
    public dynamicRoutes: RouteConfig[] = [];

    @Mutation
    private SET_ROUTES(routes: RouteConfig[]) {
        this.routes = constantRoutes.concat(routes);
        this.dynamicRoutes = routes;
    }

    @Action
    public GenerateRoutes(roles: any[]) {
        const rolesRouter = addRouterRoles(asyncRoutes, roles);
        const accessedRoutes = filterAsyncRoutes(rolesRouter, roles);
        this.SET_ROUTES(accessedRoutes);
    }
}

export const PermissionModule = getModule(Permission);
