import { DirectiveOptions } from 'vue';

/*
    Delete: false 删除
    Export: true 导入
    Insert: true 添加
    Other: true  其它
    Select: true 选择
    Update: true 编辑
*/
export const permission: DirectiveOptions = {
    inserted(el, binding) {
        try {
            const {
                value: {
                    role,
                    route
                }
            } = binding;
            const meta = route.meta || [];
            if (role && role instanceof Array && role.length > 0) {
                const hasPermission = role.some((item) => {
                    return meta.roles[item];
                });
                if (!hasPermission) {
                    el.style.display = 'none';
                }
            } else {
                throw new Error('permission error');
            }
        } catch (err) {
            console.warn(err);
        }
    }
};
