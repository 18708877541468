import Vue, { DirectiveOptions } from 'vue';
import 'normalize.css';
import ElementUI from 'element-ui';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';

import App from '@/App.vue';
import store from '@/store';
import router from '@/router/index';
import '@/permission';
import * as directives from '@/directives';
import YwhzComponents from '@/utils/install';

try {
    Vue.use(ElementUI, { size: 'medium', zIndex: 3000 });
    Vue.use(YwhzComponents, {
        componentPrefix: 'Ywhz'
    });
} catch (err) {
    console.warn(err);
}

Vue.config.productionTip = false;

// 全局自定义指令
Object.keys(directives).forEach(key => {
    Vue.directive(key, (directives as { [key: string ]: DirectiveOptions })[key]);
});

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
