import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { login, logout } from '@/api/users';
import { getToken, setToken, removeToken, getUserData, setUserData, removeUserData } from '@/utils/cookies';
import store from '@/store';

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule {
    public token = getToken() || '';
    public userData = getUserData() || {};
    public avatar = '';
    public roles: string[] = []

    @Mutation
    private SET_TOKEN(token: string) {
        setToken(token);
        this.token = token;
    }

    @Mutation
    private SET_USER_DATA(data: Object) {
        setUserData(data);
        this.userData = data;
    }

    @Mutation
    private SET_AVATAR(avatar: string) {
        this.avatar = avatar;
    }

    @Mutation
    private SET_ROLES(roles: string[]) {
        this.roles = roles;
    }

    @Action
    public async Login(userInfo: { UserName: string, PassWord: string }) {
        const { Data } = await login(userInfo);
        this.SET_TOKEN(Data.Token);
        this.SET_USER_DATA(Data);
    }

    @Action({ rawError: true })
    public async roleRouter() {
        if (this.token === '') {
            throw Error('令牌未定义！');
        }
        this.SET_ROLES(['admin']);
        this.SET_AVATAR(require('@/assets/user_avatar.gif'));
    }

    @Action
    public ResetToken() {
        removeToken();
        this.SET_TOKEN('');
        this.SET_ROLES([]);
        removeUserData();
    }

    @Action
    public async LogOut() {
        if (this.token === '') {
            throw Error('注销：令牌未定义！');
        }
        await logout();
        removeToken();
        removeUserData();
        this.SET_TOKEN('');
    }

}

export const UserModule = getModule(User);
