























import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { AppMain, Navbar, Sidebar, TagsView } from './components';

import { DeviceType, AppModule } from '@/store/modules/app';
import ResizeMixin from './mixin/resize';

@Component({
    name: 'Layout',
    components: {
        AppMain,
        Navbar,
        Sidebar,
        TagsView
    }
})
export default class extends mixins(ResizeMixin) {
    public handleClickOutside() {
        AppModule.CloseSideBar(false);
    }

    get classObj() {
        return {
            hideSidebar: !this.sidebar.opened,
            openSidebar: this.sidebar.opened,
            withoutAnimation: this.sidebar.withoutAnimation,
            mobile: this.device === DeviceType.Mobile
        };
    }
}
