// export const isValidUsername = (str: string) => ['admin', 'editor'].indexOf(str.trim()) >= 0

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path);

export const validators = {
    required: {
        required: true,
        message: '必填项未填写',
        trigger: 'blur',
        validator: (rule: any, value: any, callback: any) => {
            try {
                // 数组
                if (Array.isArray(value)) {
                    if (value.length <= 0) {
                        callback(new Error('必填项未填写'));
                    } else {
                        callback();
                    }
                    return;
                }
                if (['', undefined, null].includes(value) || value.toString()?.match(/^\s+$/)?.length) {
                    callback(new Error('必填项未填写'));
                } else {
                    callback();
                }
            } catch (error: any) {
                console.warn('$validators ERROR', error);
                callback(new Error(error.message));
            }
        }
    }
};

// 权限验证
/*
    Delete: false 删除
    Export: true 导入
    Insert: true 添加
    Other: true  其它
    Select: true 选择
    Update: true 编辑
*/
export const checkPermission = (role: string[], route: any): boolean => {
    const meta = route.meta || [];
    if (role && role instanceof Array && role.length > 0) {
        const hasPermission = role.some((item) => {
            return meta.roles[item];
        });
        return hasPermission;
    } else {
        return false;
    }
};
