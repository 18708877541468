
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { VNode } from 'vue';
import { Expressionable, ConditionalTypeEnum } from '@/utils/search';
import { formatTimeFromDate2 } from '@/utils/tool';

@Component({
    name: 'YwhzSearchCard',
    components: {}
})
export default class YwhzSearchCard extends Vue {
    @Prop({ type: Array, default: () => [] }) private searchAttrs: any;

    private searchAttrsList: any = [];

    created() {
        this.init();
    }

    // @Emit('search')
    private searchChange() {
        return Expressionable(this.transform());
    }

    @Emit('reset')
    private resetChange(val: any) {
        // 清空input
        this.searchAttrsList.map((item: any) => {
            item.attrs.value = null;
            return item;
        });
        return val;
    }

    private init() {
        this.searchAttrsList = this.searchAttrs;
        try {
            for (let i: any = 0; i < this.searchAttrsList.length; i++) {
                const row: any = this.searchAttrsList[i];
                //  不管是组件，还是字符串 都直接加上attrs 属性
                row.attrs = {
                    value: null,
                    ...row.attrs
                };
            }
        } catch (err) {
            console.warn(err);
        }
    }

    private transform() {
        const valueQuery = [];
        this.searchAttrsList.map((row: any) => {
            const item = row;
            if (item.attrs.value) {
                // 如果是日期格式 且这个日期是 饿了么 开始跟结束日期，根据后端接口查询条件，生成特殊的格式
                if (item.prop === 'CustomCreateTime' && item.attrs.value.length) {
                    // 一个拼接数据格式
                    const dates = new Date(item.attrs.value[1]);
                    dates.setHours(23);
                    dates.setMinutes(59);
                    dates.setSeconds(59);
                    valueQuery.push({
                        lable: 'CreateTime',
                        value: formatTimeFromDate2(item.attrs.value[0]),
                        query: ConditionalTypeEnum['大于等于']
                    }, {
                        lable: 'CreateTime',
                        value: formatTimeFromDate2(dates),
                        query: ConditionalTypeEnum['小于等于']
                    });
                } else {
                    valueQuery.push({
                        lable: item.prop,
                        value: item.attrs.value,
                        query: item.query.type
                    });
                }
            }
            return item;
        });
        return valueQuery;
    }

    private render(h: any) {
        const childVnode: Array<VNode> = [];
        try {
            // 绘制输入框名字 和 输入框
            for (let i: any = 0; i < this.searchAttrsList.length; i++) {
                const row: any = this.searchAttrsList[i];
                childVnode.push(
                    h('div', {
                        class: 'search-item-wrap'
                    }, [
                        h('div', {
                            class: 'label-name',
                            domProps: {
                                innerHTML: row.label
                            }
                        }),
                        h('div', {
                            class: 'lable-input'
                        }, [
                            h(row.component, {
                                ref: row.prop,
                                attrs: {
                                    ...row.attrs
                                },
                                on: {
                                    input: (val: any) => {
                                        row.attrs.value = val;
                                    },
                                    change: (val: any) => {
                                        row.attrs.value = val;
                                    }
                                }
                            })
                        ])
                    ])
                );
            }

            const buttonRroup: Array<VNode> = [h('div',
                {
                    class: 'search-box'
                },
                [
                    h('el-button', {
                        attrs: {
                            type: 'primary',
                            icon: 'el-icon-search'
                        },
                        on: {
                            click: () => {
                                this.$emit('search', this.searchChange());
                                // this.searchChange();
                            }
                        }
                    }, '搜索'),
                    h('el-button', {
                        attrs: {
                            type: 'warning',
                            icon: 'el-icon-refresh-left'
                        },
                        on: {
                            click: (val: any) => {
                                this.resetChange(val);
                            }
                        }
                    }, '重置')
                ]
            )];

            return h('div', {
                class: 'search-text-wrap'
            },
            [
                ...childVnode,
                ...buttonRroup
            ]);
        } catch (err) {
            console.warn(err);
        }
    }

}
