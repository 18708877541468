






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'Hamburger'
})
export default class extends Vue {
    @Prop({ default: false }) public isActive!: boolean

    public toggleClick() {
        this.$emit('toggle-click');
    }
}
