
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { VNode } from 'vue';

@Component({
    name: 'YwhzPaginationCard',
    components: {}
})
export default class YwhzPaginationCard extends Vue {
    @Prop({
        type: Object,
        default: {
            page: 1,
            pageSize: 10,
            total: 1
        }
    })
    private pagination: any;

    // 翻页 currentPage 改变时会触发
    @Emit('handleCurrentChange')
    private handleCurrentChange(page: any) {
        return page;
    }

    // pageSize 改变时会触发
    @Emit('handleSizeChange')
    private handleSizeChange(pageSize: number) {
        return pageSize;
    }

    private render(h: any) {
        try {
            const paginationDemo: Array<VNode> = h('el-pagination', {
                props: {
                    layout: 'total, prev, pager, next, sizes',
                    hideOnSinglePage: true,
                    pageSizes: [10, 20, 30, 40],
                    'page-size': this.pagination.size,
                    ...this.pagination
                },
                on: {
                    'current-change': (page: any) => {
                        this.handleCurrentChange(page);
                    },
                    'size-change': (pageSize: any) => {
                        this.handleSizeChange(pageSize);
                    }
                }
            });
            return h('div', {
                // class: `pagination-main-wrap ${(this.pagination.size < this.pagination.total) ? '' : 'is-hide'}`
                class: 'pagination-main-wrap'
            }, [
                paginationDemo
            ]);
        } catch (err) {
            console.warn(err);
        }
    }
}
