












import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';

@Component({
    name: 'Basic',
    components: {}
})
export default class Basic extends Vue {
    public get user() {
        return UserModule.userData;
    }
}
