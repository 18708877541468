import { Loading, Message } from 'element-ui';

const getUuiD = () => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

class LoadingClass {
    private loadingPro: any = null;

    public lodingService(config?: any) {
        this.loadingPro = Loading.service({
            lock: true,
            text: '加载中...',
            ...config
        });
    }

    public lodingClose() {
        this.loadingPro.close();
    }

}

const messageTit = (config?: any) => {
    Message({
        type: 'success',
        ...config
    });
};

export const fillZero = (str: any) => {
    let realNum;
    if (str < 10) {
        realNum = '0' + str;
    } else {
        realNum = str;
    }
    return realNum;
};

enum formTimeTypeEnum {
    '年月日' = 1,
}

export const formatTimeFromDate2: any = (time: any, type?: formTimeTypeEnum) => {
    const d = new Date(time);
    const year = fillZero(d.getFullYear()) + '-';
    const month = fillZero((d.getMonth() + 1)) + '-';
    const date = fillZero(d.getDate()) + '';
    const hours = fillZero(d.getHours()) + ':';
    const minutes = fillZero(d.getMinutes()) + ':';
    const seconds = fillZero(d.getSeconds());
    if (type === formTimeTypeEnum['年月日']) {
        return (`${year}${month}${date}`);
    }
    return (`${year}${month}${date} ${hours}${minutes}${seconds}`);
};

export const formatTimeFromDate: any = (time: any) => {
    const d = new Date(time);
    const year = d.getFullYear() + '年';
    const month = (d.getMonth() + 1) + '月';
    const date = d.getDate() + '日';
    const hours = d.getHours() + '时';
    const minutes = d.getMinutes() + '分';
    return (`${year}${month}${date}${hours}${minutes}`);
};

export {
    LoadingClass,
    getUuiD,
    messageTit
};
