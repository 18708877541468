import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import { UserModule } from '@/store/modules/user';
import router from '@/router/index';
const messageErr: string = '网络请求超时...';

const service: any = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
    // timeout: 5000
});

// 请求拦截器
service.interceptors.request.use((config: any) => {
        // 向每个请求添加X-Access-Token头，您可以在此处添加其他自定义头
        if (UserModule.token) {
            config.headers['X-Access-Token'] = UserModule.token;
            // eslint-disable-next-line dot-notation
            config.headers['Authorization'] = 'Bearer ' + UserModule.token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use((response: any) => {
        const { Code, Msg } = response.data;
        // 根据自己实际业务判断
        if (Code === 0) {
            return Promise.resolve(response.data);
        }

        if (Code === 401) {
            MessageBox.confirm(
                '您已注销，请尝试再次登录。',
                '注销',
                {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                UserModule.ResetToken();
                location.reload(); // 防止vue路由器出现错误
            });
            return Promise.reject(Msg);
        }

        // 无权限
        if (Code === 3) {
            router.push({
                path: '/401'
            });
            return Promise.resolve(response.data);
        }
        // 不出现以上code的情况下
        Message({
            message: Msg || messageErr,
            type: 'error',
            duration: 5 * 1000
        });
        return Promise.reject(Msg);
    },
    (error: any) => {
        Message({
            message: error,
            type: 'error',
            duration: 5 * 1000
        });
        console.warn(error);
        return Promise.reject(error);
    }
);

export default service;
