





































import path from 'path';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { isExternal } from '@/utils/validate';
import SidebarItemLink from './SidebarItemLink.vue';

@Component({
    // 在此处设置“name”，以防止uglifyjs导致递归组件不工作
    // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
    name: 'SidebarItem',
    components: {
        SidebarItemLink
    }
})
export default class extends Vue {
    @Prop({ required: true }) public item!: RouteConfig
    @Prop({ default: false }) public isCollapse!: boolean
    @Prop({ default: true }) public isFirstLevel!: boolean
    @Prop({ default: '' }) public basePath!: string

    get showingChildNumber() {
        if (this.item.children) {
            const showingChildren = this.item.children.filter((item) => {
                if (item.meta && item.meta.hidden) {
                    return false;
                } else {
                    return true;
                }
            });
            return showingChildren.length;
        }
        return 0;
    }

    get theOnlyOneChild() {
        if (this.showingChildNumber > 1) {
            return null;
        }
        if (this.item.children) {
            for (const child of this.item.children) {
                if (!child.meta || !child.meta.hidden) {
                    return child;
                }
            }
        }
        // 如果没有子对象，请移除路径并返回自身，
        // 因为这个。basePath已包含项的路径信息
        return { ...this.item, path: '' };
    }

    public resolvePath(routePath: string) {
        if (isExternal(routePath)) {
            return routePath;
        }
        if (isExternal(this.basePath)) {
            return this.basePath;
        }
        return path.resolve(this.basePath, routePath);
    }
}
