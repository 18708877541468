















import { Component, Vue, Ref } from 'vue-property-decorator';

import { DeliveryStationChangePassword } from '@/api/users';
import { validators } from '@/utils/validate';
import { messageTit } from '@/utils/tool';
@Component({
    name: 'Basic',
    components: {
    }
})
export default class Basic extends Vue {
    @Ref('form') private readonly formRef: any;

    public formData: any = {
        OldPassWord: '',
        NewPassWord: ''
    };

    public formRules: any = {
        OldPassWord: [validators.required],
        NewPassWord: [validators.required]
    };

    public async submit() {
        try {
            await this.formRef.validate();
            await DeliveryStationChangePassword(this.formData);
            messageTit({
                message: '修改成功,請重新登錄'
            });
            this.close();
        } catch (error) {
            console.warn(error);
        }
    }

    public close() {
        this.formRef.resetFields();
    }
}
