import request from '@/utils/request';
/*
用户登陆
GET /Login
接口ID：44498205
接口地址：https://www.apifox.cn/web/project/1774034/apis/api-44498205

*/
export const login = (params: any) =>
    request({
        url: '/DeliveryStation/Login',
        method: 'GET',
        params
    });

/*

退出登陆，清空token信息
GET /LogOut
接口ID：44498208
接口地址：https://www.apifox.cn/web/project/1774034/apis/api-44498208

*/

export const logout = (params?: any) =>
    request({
        url: '/DeliveryStation/LogOut',
        method: 'GET',
        params
    });

/*
修改密码
POST /ChangePassword
接口ID：44498209
接口地址：https://www.apifox.cn/web/project/1774034/apis/api-44498209

*/
export const DeliveryStationChangePassword = (data?: any) =>
    request({
        url: '/DeliveryStation/ChangePassword',
        method: 'POST',
        data
    });
